import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet molt petit de 2mm de diàmetre, blanquinosos o grogosos, que quan maduren s’obren per la part superior en forma d’estrelleta de 6 a 9 punxes. A l’interior es troba un peridiol en forma d’esfera grogosa que guarda les espores. Quan l’humitat és òptima i per òsmosi aquestes esferetes són projectades a distància. Les espores són blanques en massa, hialines i llises, de 8 x 5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      